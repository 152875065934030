import React, { useState } from "react";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import donation_hands from "../../Assets/donation_hands.jpeg";

const Donation = () => {
  const [amount, setAmount] = useState("");
  const [email, setEmail] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [phone, setPhone] = useState("");
  const [isAnonymous, setIsAnonymous] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);

    const userAmount = parseInt(amount.replace(/\s/g, ""), 10);

    // Vérification du montant minimum et maximum
    if (userAmount < 1 || userAmount > 99999999) {
      setError(
        "Le montant doit être compris entre 1 GNF et 99 999 999 GNF (Franc Guinéen)."
      );
      setLoading(false);
      return;
    }

    // Divise le montant par 100 pour le gateway de paiement
    // const gatewayAmount = userAmount / 100;

    const anonymousValues = {
      email: "lambe.khili@gmail.com",
      firstname: "Lambé",
      lastname: "Khili",
      phone: "613000000",
    };

    try {
      const response = await axios.post("/api/makepayment", {
        email: isAnonymous ? anonymousValues.email : email,
        firstname: isAnonymous ? anonymousValues.firstname : firstname,
        lastname: isAnonymous ? anonymousValues.lastname : lastname,
        phone: isAnonymous ? anonymousValues.phone : phone,
        // amount: gatewayAmount, // Utilise le montant ajusté pour la passerelle de paiement
        amount: userAmount,
      });

      if (response.data && response.data.gatewayUrl) {
        window.location.href = response.data.gatewayUrl;
      } else {
        setError("Échec de l'initialisation du paiement.");
      }
    } catch (error) {
      setError(error.message);
    }

    setLoading(false);
  };

  const formatAmount = (value) => {
    return value.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  };

  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-100">
      <div className="w-full max-w-4xl p-8 space-y-6 bg-white rounded-lg shadow-md">
        <div className="mb-6">
          <blockquote className="text-xl italic font-semibold text-center text-gray-700">
            "La générosité est la fleur de la justice." - Nathaniel Hawthorne
          </blockquote>
        </div>
        <div className="flex flex-col items-center md:flex-row md:space-x-6">
          <div className="w-full md:w-1/2">
            <img
              src={donation_hands}
              alt="Donation Hands"
              className="rounded-lg shadow-md"
            />
          </div>
          <div className="w-full md:w-1/2">
            <h2 className="text-2xl font-bold text-center text-blue-800">
              Faire un don
            </h2>
            {error && <p className="text-red-500 text-center">{error}</p>}

            {/* Note explicative avec couleur */}
            {/* <div className="bg-yellow-100 text-yellow-800 border border-yellow-400 rounded-md p-4 mb-4">
              <strong>Note :</strong> Le montant que vous saisissez ici pourrait
              ne pas s'afficher correctement lors de la confirmation du paiement
              via la passerelle. Par exemple, si vous entrez 10 000 GNF, il se
              peut que 100 GNF s'affiche. Cependant, soyez assuré que la banque
              débitera bien 10 000 GNF de votre compte.
            </div> */}

            <form onSubmit={handleSubmit} className="space-y-4">
              <label className="block text-center">
                <input
                  type="checkbox"
                  checked={isAnonymous}
                  onChange={(e) => setIsAnonymous(e.target.checked)}
                  className="mr-2"
                />
                Don Anonyme
              </label>
              {!isAnonymous && (
                <>
                  <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    placeholder="Email"
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                  <input
                    type="text"
                    value={firstname}
                    onChange={(e) => setFirstname(e.target.value)}
                    placeholder="Prénom"
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                  <input
                    type="text"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                    placeholder="Nom"
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                  <input
                    type="tel"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    placeholder="Numéro de téléphone"
                    className="w-full p-2 border border-gray-300 rounded"
                    required
                  />
                </>
              )}
              <div>
                <label className="block text-left">Montant (GNF)</label>
                <input
                  type="text"
                  value={formatAmount(amount)}
                  onChange={(e) => setAmount(e.target.value)}
                  placeholder="Montant"
                  className="w-full p-2 border border-gray-300 rounded"
                  required
                  maxLength={11} // Limite la saisie à 99 999 999
                />
              </div>
              <button
                type="submit"
                className="w-full py-2 text-white bg-blue-800 rounded hover:bg-blue-900"
                disabled={loading}
              >
                {loading ? (
                  <ClipLoader size={20} color={"#ffffff"} loading={loading} />
                ) : (
                  "Faire un Don"
                )}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Donation;
