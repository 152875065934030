import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="flex items-center justify-center min-h-screen p-8 bg-gray-100">
      <div className="w-full max-w-3xl bg-white rounded-lg shadow-lg p-8">
        <h1 className="text-3xl font-bold text-blue-800 mb-6 text-center">
          Politique de Confidentialité et d'Anonymat
        </h1>
        <p className="text-base mb-6 text-gray-700 text-center">
          Nous respectons la confidentialité et l'anonymat de nos donateurs.
          Voici comment nous recueillons, utilisons et protégeons vos
          informations.
        </p>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-left">
            Collecte d'informations
          </h2>
          <p className="text-base mb-4 text-gray-700 text-left">
            Lors de vos dons, nous recueillons les informations suivantes :
          </p>
          <ul className="list-disc list-inside text-gray-700 text-left">
            <li>Nom</li>
            <li>Prénom</li>
            <li>Email</li>
            <li>Numéro de téléphone</li>
            <li>Montant du don</li>
          </ul>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-left">
            Utilisation des informations
          </h2>
          <p className="text-base text-gray-700 text-left">
            Vos informations sont utilisées uniquement pour le traitement du
            don. Elles ne seront pas partagées avec des tiers sans votre
            consentement.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-left">
            Dons anonymes
          </h2>
          <p className="text-base mb-4 text-gray-700 text-left">
            Si vous optez pour un don anonyme, nous remplirons les informations
            suivantes avec des valeurs génériques :
          </p>
          <ul className="list-disc list-inside text-gray-700 text-left">
            <li>Nom : Lambé</li>
            <li>Prénom : Khili</li>
            <li>Email : lambe.khili@gmail.com</li>
            <li>Numéro de téléphone : +224 624 37 24 90</li>
          </ul>
          <p className="text-base text-gray-700 text-left">
            Ces informations permettent de traiter votre don tout en préservant
            votre anonymat.
          </p>
        </section>

        <section className="mb-6">
          <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-left">
            Protection des informations
          </h2>
          <p className="text-base text-gray-700 text-left">
            Nous mettons en œuvre des mesures de sécurité pour protéger vos
            informations personnelles. L'accès est restreint aux seules
            personnes autorisées.
          </p>
        </section>

        <hr className="my-8 border-t border-gray-300" />

        <section>
          <h2 className="text-2xl font-semibold text-blue-700 mb-4 text-left">
            Contact
          </h2>
          <p className="text-base text-gray-700 text-left">
            Pour toute question concernant cette politique, contactez-nous à :
          </p>
          <p className="text-base text-left">
            <a
              href="mailto:ong.preventionroutiereguinee@gmail.com"
              className="text-blue-600 underline"
            >
              ong.preventionroutiereguinee@gmail.com
            </a>{" "}
            ou{" "}
            <a
              href="mailto:contact@arlcir.com"
              className="text-blue-600 underline"
            >
              contact@arlcir.com
            </a>
            .
          </p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
