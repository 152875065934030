import React from "react";

function NotFound() {
  return (
    <div
      style={{
        textAlign: "center",
        marginTop: "50px",
      }}
    >
      <h1>404 - Page non trouvée</h1>
      <p>Désolé, la page que vous cherchez n'existe pas...</p>
    </div>
  );
}

export default NotFound;
